import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import MeinSeo from '../components/seo'
import {motion} from 'framer-motion'
export default function MayerLiebFrauenstr(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >  
        <MeinSeo title="Das Schicksal der jüdischen Familie Mayer aus Oberwesel, Liebfrauenstraße" description="Bilder und Dokumente zum Schicksal der Familie Mayer aus Oberwesel, Liebfrauenstraße" />
        <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid py="8" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading variant="utitel">Liebfrauenstraße 50
            <br/>
            Geschwister Albert, Moritz, Thekla, Jenny und Leo Mayer
            </Heading>
            
            <Text variant="solid">
            Salomon bar Mayer (*1746), der sich ab 1808 Josephe Mayer nannte, verheiratet mit Helene bath Abraham (*1766), war aus Sprendlingen an der Nahe zugewandert. Sein Sohn Leopold (*1796) hieß bis 1808 Bonavit bar Mayer, weshalb seine Nachfahren »Mayer Bonnewitz« gerufen wurden. Dessen Sohn Eduard Mayer kaufte das nach 1887 erbaute Haus in der Liebfrauenstraße 50, wo er mit seiner Frau Emma, geb. Levy, wohnte und seit der Jahrhundertwende eine Wein- und Spirituosenhandlung führte. Hier kamen die Kinder Albert (*1874), Moritz (*1876), Thekla (*1878), Jenny (*1879) und Leo (*1881) zur Welt. Bis zu ihrer Deportation 1942 wohnten in der Liebfrauenstraße 50 der ledige Winzer und Weinhändler Moritz Mayer, letzter Vorsteher der Jüdischen Gemeinde, seine ledige Schwester Jenny sowie ihr Bruder Leo Mayer mit seiner Frau Ida, geb. Wolf, zuletzt auch Emma und Willi Frenkel. Sie wurden alle ermordet, ebenso Albert Mayer.
            </Text>
            <Heading variant="utitel">1. April 1933</Heading>
            <Text variant="solid">Leo Mayer, geboren am 31.10.1881 in Oberwesel, und seine Frau Ida, geb. Wolf, geboren am 18.12.1895 in Planig (heute zu Bad Kreuznach gehörig) nehmen Idas Nichte Anna Auguste Wolf vorübergehend als Pflegekind auf. Sie bliebt bis zum 28.08.1934 bei ihnen.</Text>
            <Heading variant="utitel">18. September 1936</Heading>
            <Text variant="solid">Thekla Faber, geb. Mayer, stirbt in Oberwesel, ihr Mann Jakob 1938 in Düsseldorf</Text>
            <Heading variant="utitel">23. September 1937</Heading>
            <Text variant="solid">Moritz Mayer, geboren am 08.05.1876 in Oberwesel, wird Vorsteher der jüdischen Gemeinde.</Text>
            
            <Heading variant="utitel">Januar 1939</Heading>
            <Text variant="solid">Die Stadt will die Synagoge erwerben. Moritz Mayer wird mehrfach von Bürgermeister Fritz Bosche einbestellt. Er soll die Kultusgemeinde als privaten Verein eintragen lassen</Text>
            <Heading variant="utitel">29. Juli 1940</Heading>
            <Text variant="solid">Der Telefonanschluss von Moritz Mayer wird gekündigt, von dessen Apparat mit dem US-Consulat in Stuttgart telefoniert werden konnte. Nun ermöglicht der Elektromeister Jäckel heimlich Telefongespräche.</Text>
            <Heading variant="utitel">April 1941</Heading>
            <Text variant="solid">
            Das Haus Liebfrauenstraße 50 wird zum »Judenhaus« erklärt. Die letzten Wochen vor ihrer Flucht verbringen Jakob und Clementine Mayer hier. Auch Emma und Wilhelm Frenkel aus der Kirchstraße haben ein Zimmer bei Mayers.
            </Text>
            <Heading  variant="utitel">2. April 1942</Heading>
            <Text variant="solid">Moritz Mayer und seine Schwester Jenny, geboren am 11.11.1879, sowie ihr Bruder Leo Mayer und seine Frau Ida werden ebenso wie Frenkels nach Bad Salzig verbracht, wo sie zu den Bopparder Juden in das marode Hotel Schwan gesperrt werden.</Text>
            <Heading  variant="utitel">29. April 1942</Heading>
            <Text variant="solid">Leo und Ida Mayer werden von Salzig zurück nach Oberwesel gebracht, da Leo im Weltkrieg für den Fronteinsatz ausgezeichnet worden ist. Sie müssen in das »Judenhaus« zu Adolf Seligmann ziehen.</Text>
            <Heading  variant="utitel">30. April 1942</Heading>
            <Text variant="solid">Moritz und Jenny Mayer werden von Bad Salzig zum Güterbahnhof Koblenz-Lützel gebracht und ins Ghetto Krasniczyn transportiert.</Text>
            <Heading  variant="utitel">22. Juli 1942</Heading>
            <Text variant="solid">Albert Mayer, zuletzt mit seiner Frau Johanna wohnhaft in Düsseldorf, Sternstr. 14, wird nach Theresienstadt deportiert. Er stirbt am 17.09.1942.</Text>
            <Heading  variant="utitel">27. Juli 1942</Heading>
            <Text variant="solid">Vor dem Rathaus haben sich die Juden einzufinden, auch Leo und Ida Mayer. Von Koblenz-Lützel werden sie nach Theresienstadt deportiert. Von dort werden sie nach Auschwitz verschleppt und umgebracht.</Text>
           
            </Box>
             <Box>
                <Box p="1">
                    <StaticImage src="../images/solo/clementine-mayer.jpg"/>
                    <Text variant="Bild">Clementine Mayer geb. Frenkel </Text>
                </Box>
               
                <Box p="1">
                    <StaticImage src="../images/solo/Moritz-Mayer.jpg" alt="Moritz Mayer" />
                    <Text variant="Bild">Moritz Mayer</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/Leo-Mayer.jpg" alt="Leo Mayer" />
                    <Text variant="Bild">Leo Mayer</Text>
                </Box>
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}